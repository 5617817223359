import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';

import AvailableMentorsList from './list/mentors-list';

import PageHeader from '../../../../../components/common/page-header';
import SEO from '../../../../../components/seo';
import { Chat } from '../../../../../components/chat';
import { tenantLabel, programShortName } from '../../../../../siteContent';
import { isRealTimeChatEnabled as $isRealTimeChatEnabled } from '../../../../../state/selectors/tenant';

const ManualMentorsMatchList: React.FC<RouteComponentProps> = (_: RouteComponentProps) => {
  const isRealTimeChatEnabled = useSelector($isRealTimeChatEnabled());
  const breadcrumbs: Array<React.ReactNode | string> = [
    <Link key="1" to="/myhsf">
      {tenantLabel} Dashboard
    </Link>,
    `${programShortName} Mentors`,
  ];

  const description: string =
    `Explore our wide variety of experienced professionals. All of our ${programShortName} mentors have been thoroughly vetted and hand picked to ensure lorem ipsum dolor sit amet,\n` +
    'consecrated advising elite,sed do emus temper incident ut labor.';

  return (
    <>
      <SEO title="Mentors" />
      <PageHeader title={`${programShortName} Mentors`} breadcrumb={breadcrumbs} description={description} noShadow />
      <AvailableMentorsList />
      {isRealTimeChatEnabled && <Chat />}
    </>
  );
};

export default ManualMentorsMatchList;
